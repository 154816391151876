import React from "react";
import { Formik, FormikHelpers } from "formik";
import { FormModel } from "lib/models";
import { formSchema } from "./Form.schema";
import { Form } from "./Form";

import "./Form.scss";
import { initialBgs, initialTexts } from "lib/config";
import { makeImage } from "lib/handlers/makeGif";
import { ImageDisplay } from "../ImageDisplay";

export const FormContainer: React.FC = () => {
  const [image, setImage] = React.useState<string>(); // base64 image
  const [text, setText] = React.useState<string>("");

  const initialValues: FormModel = {
    text: initialTexts[Math.floor(Math.random() * initialTexts.length)],
    size: "sm",
    color: "#ffffff",
    background: initialBgs[Math.floor(Math.random() * initialBgs.length)],
  };

  const handleSubmit = (
    values: FormModel,
    actions: FormikHelpers<FormModel>
  ) => {
    const cachedValues = { ...values };

    const imageResp = makeImage(cachedValues);

    if (imageResp) {
      setImage(imageResp);
      setText(values.text);
    } else {
      console.error("COULD NOT CREATE IMAGE. FML! 😫");
    }

    actions.setSubmitting(false); // done either way
    actions.setValues(cachedValues); // We don't want to lose our VALUES!
  };

  const resetImage = () => {
    setImage(undefined);
  };

  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={formSchema}
        initialValues={initialValues}
        validateOnChange
        validateOnBlur
        validateOnMount
        onSubmit={handleSubmit}
      >
        <Form onReset={resetImage} />
      </Formik>
      <ImageDisplay image={image} text={text} />
    </>
  );
};
