import GIFEncoder from "gifencoder";
import { FormModel } from "lib/models";
import {
  FONT_FAMILY,
  FRAME_DURATION,
  imageSizeMap,
  PADDING,
  ZOOM_STEPS,
} from "lib/config";

/**
 * Calculate the starting font size per word
 *
 * This math is very approximate. If it causes trouble later, I'll adjust.
 *
 * @param word
 * @param width
 * @returns a bare number for pixels
 */
const calculateStartingSize = (word: string, width: number): number => {
  const chars = word.length;
  const padding = 2 * PADDING; // padding * 2 for each side. This gives us flex space for wide letters.
  const widthPadded = width - padding;
  const charWidth = widthPadded / chars;
  const height = charWidth * (5 / 3); // 5:3 is the average height to width ration for the roman alphabet

  return Math.floor(height);
};

/**
 * Converts a byte array to base64
 */
const arrayBufferToBase64 = (buffer: number[]): string => {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

/**
 * Makes an animate gif based on supplied props. Returns base64 string.
 */
export const makeImage = (props: FormModel): string | undefined => {
  // setup props
  const { text, color, background, size: sizeKey } = props;
  const size: number = Object.keys(imageSizeMap).includes(sizeKey)
    ? imageSizeMap[sizeKey]
    : imageSizeMap.sm;
  const words = text.split(" ");

  // build image
  const encoder = new GIFEncoder(size, size);

  encoder.start();
  encoder.setRepeat(0); // 0 repeats, -1 does not
  encoder.setDelay(FRAME_DURATION); // delay between frames in milliseconds
  // encoder.setQuality(10); // default is 10... adjust later maybe as needed

  // Setup canvas and context
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) return undefined;

  /**
   * Function to set the background color. Use for each frame.
   */
  const setBg = () => {
    ctx.fillStyle = background;
    ctx.fillRect(0, 0, size, size);
  };

  const addText = async (word: string, fontSize: number) => {
    const center = size / 2;

    ctx.fillStyle = color;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.font = `${fontSize}px ${FONT_FAMILY}`;
    ctx.fillText(word, center, center);
  };

  // Set bg color
  words.forEach((word) => {
    const baseFontSize = calculateStartingSize(word, size);

    for (let i = 0; i < ZOOM_STEPS; i++) {
      setBg();
      const zoomMultiplier = i * 0.3 + 1;
      const stepFontSize = baseFontSize * zoomMultiplier;
      addText(word, stepFontSize);
      encoder.addFrame(ctx);
    }

    // Add a single blank frame for the space between the words
    setBg();
    encoder.addFrame(ctx);
  });

  // Add a few blank frames at the end
  setBg();
  encoder.addFrame(ctx);
  encoder.addFrame(ctx);
  encoder.addFrame(ctx);

  encoder.finish();

  const byteArray = encoder.out.data;
  const base64Image = `data:image/gif;base64,${arrayBufferToBase64(byteArray)}`;

  return base64Image;
};
