import { object, string } from "yup";

const COLOR_REGEX = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
const COLOR_ERROR = (val: string) => `${val} is not a valid hex color`;

export const formSchema = object().shape({
  text: string().required(),
  size: string().required().oneOf(["sm", "md", "lg"]),
  color: string().required().matches(COLOR_REGEX, {
    message: COLOR_ERROR,
  }),
  background: string().required().matches(COLOR_REGEX, {
    message: COLOR_ERROR,
  }),
});
