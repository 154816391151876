export const initialTexts = [
  "Nice face!",
  "Suit up!",
  "Good vibes only",
  "Yes pls!",
  "Just no",
  "Never give up",
  "Sprinkle Sprinkle",
  "Dip it and ship it!",
  "Lick it and stick it!",
  "Good job",
  "Very clever",
  "Wow, just wow.",
  "It wasn't me",
  "I don't think so",
  "I got a real red wagon",
  "W W J D",
  "Uh... no",
  "That's what she said 😉",
  "Yummy yummy",
  "Nice push",
];
export const initialBgs = [
  "#ed40c6",
  "#3ad8b6",
  "#933ad8",
  "#438bfc",
  "#fc7353",
  "#eff00a",
];
