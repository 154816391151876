import React from "react";
import { Field, getIn, useFormikContext } from "formik";
import { FormModel } from "lib/models";

import "./CustomSelect.scss";

type StringLike = string | number;

type CustomSelectProps = {
  name: string;
  label: string;
  hideLabel?: boolean;
  options: { [key: string]: StringLike };
};

const firstInitial = (text: string) => text.charAt(0).toUpperCase();

export const CustomSelect: React.FC<CustomSelectProps> = ({
  name,
  options,
  label,
  hideLabel = false,
}) => {
  const { values } = useFormikContext<FormModel>();

  const selectedValue = getIn(values, name);
  const aspectValue = firstInitial(selectedValue || name);

  return (
    <>
      <label htmlFor={name} className={hideLabel ? "assistive-text" : ""}>
        {label}
      </label>
      <div className="row align-items-center">
        <div className="col-auto" style={{ paddingRight: 0 }}>
          <span className="custom-select--aspect" aria-hidden>
            {aspectValue}
          </span>
        </div>
        <div className="col">
          <Field as="select" name={name} className="custom-select--select">
            {Object.keys(options).map((key) => (
              <option key={key} value={key}>
                {options[key]}
              </option>
            ))}
          </Field>
        </div>
      </div>
    </>
  );
};
