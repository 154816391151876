import React from "react";
import { Field, Form as FormikForm, useFormikContext } from "formik";
import { FormModel } from "lib/models";
import { Loading, CustomSelect } from "lib/components";

export type FormProps = {
  onReset?: () => void;
};

export const Form: React.FC<FormProps> = ({ onReset }) => {
  const { isValid, isSubmitting, handleReset } = useFormikContext<FormModel>();

  const mergedReset = () => {
    handleReset();
    if (onReset) onReset();
  };

  return (
    <FormikForm>
      <h2 className="text-center">What shall we ZOOM today?</h2>
      <div className="form-item text-center">
        <label htmlFor="gifText">Your text</label>
        <Field
          type="text"
          name="text"
          className="input input-text text-center"
          autoFocus
        />
      </div>
      <div className="row justify-content-center">
        <div className="col col-auto">
          <div className="row align-items-center">
            <div className="col-auto" style={{ paddingRight: 0 }}>
              <Field type="color" name="color" className="input input-color" />
            </div>
            <label htmlFor="color" className="col">
              Text
            </label>
          </div>
        </div>
        <div className="col col-auto">
          <div className="row align-items-center">
            <div className="col-auto" style={{ paddingRight: 0 }}>
              <Field
                type="color"
                name="background"
                className="input input-color"
              />
            </div>
            <label htmlFor="background" className="col">
              Background
            </label>
          </div>
        </div>
        <div className="col col-auto">
          <CustomSelect
            name="size"
            label="Size"
            options={{ sm: "Small", md: "Medium", lg: "Large" }}
            hideLabel
          />
        </div>
      </div>
      <div className="form-actions text-center">
        <button
          type="button"
          className="btn btn-secondary"
          disabled={isSubmitting}
          onClick={mergedReset}
        >
          Clear
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={!isValid || isSubmitting}
        >
          <Loading isLoading={isSubmitting} /> Makey makey
        </button>
      </div>
    </FormikForm>
  );
};
