import { StyleColor } from "lib/models";
import React, { AnchorHTMLAttributes } from "react";

import "./BubbleLink.scss";

type BubbleLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  color?: StyleColor;
};

export const BubbleLink: React.FC<BubbleLinkProps> = (props) => {
  const { color = "primary" } = props;
  const [radiate, setRadiate] = React.useState<string>("");
  const classNameList = [
    props.className,
    "bubble-link",
    `bubble-link-${color}`,
  ];
  if (radiate) classNameList.push("radiate");

  const dazzle = () => {
    setRadiate("radiate");
    setTimeout(() => {
      setRadiate("");
    }, 300);
  };

  const mergedProps = {
    ...props,
    onClick: dazzle,
    className: classNameList.join(" "),
  };

  return <a {...mergedProps}>{props.children}</a>;
};
