// This font has to be available. We load this via the CSS.
export const FONT_FAMILY = "Open Sans";
export const PADDING = 10; // border in pixels that we want to keep clear
export const ZOOM_STEPS = 8; // how many frames of zoom should we generate
export const FRAME_DURATION = 80;

export const imageSizeMap: Record<string, number> = {
  sm: 32,
  md: 64,
  lg: 128,
};
