import React from "react";

import "./Loading.scss";

type LoadingProps = {
  isLoading?: boolean;
};

export const Loading: React.FC<LoadingProps> = ({ isLoading = true }) => {
  if (!isLoading) return null;

  return <div className="loading-indicator" aria-label="Loading..." />;
};
