import React from "react";

import "./ImageDisplay.scss";

import Pointy from "lib/assets/pointy.svg";

import { BubbleLink } from "lib/components";

type ImageDisplayProps = {
  /**
   * Base64 image
   */
  image: string | undefined;
  /**
   * Image text
   */
  text: string;
};

const PointyFinger: React.FC<{ reverse?: boolean }> = ({ reverse = false }) => {
  return (
    <div style={reverse ? { transform: "scaleX(-1)" } : undefined}>
      <Pointy
        className="finger-pointy"
        width="100"
        height="100"
        fill="currentColor"
      />
    </div>
  );
};

export const ImageDisplay: React.FC<ImageDisplayProps> = ({ image, text }) => {
  const textAsFilename =
    text
      .replace(/[^\w ]/g, "")
      .replace(/\W/g, "-")
      .toLocaleLowerCase() + ".gif";
  return image ? (
    <div className="image-display">
      <div className="image-container">
        <div className="aside">
          <PointyFinger />
        </div>
        <div className="middle">
          <div className="image">
            <img src={image} alt="Generated" title="Your wowie wow" />
          </div>
        </div>
        <div className="aside">
          <PointyFinger reverse />
        </div>
      </div>
      <div className="text-center">
        <div className="dl-link">
          <BubbleLink href={image} color="pop" download={textAsFilename}>
            <span>⬇</span>
            <span>Get it!</span>
          </BubbleLink>
        </div>
      </div>
    </div>
  ) : null;
};
