import React from "react";
import { Form, Layout } from "lib/components";

export default function App() {
  return (
    <Layout>
      <Form />
    </Layout>
  );
}
